<template>
  <div>
    <v-sheet class="pa-8">
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          xl="4">
          <v-select
            v-model="query.warehouse"
            :class="$vuetify.breakpoint.xs ? '' : 'mr-3'"
            expanded
            :items="warehouses"
            label="Warehouse"
            dense
            item-text="name"
            item-value="id"
            outlined
            @change="query.search = search"></v-select>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="5"
          lg="5"
          xl="5"
          class="d-flex align-right">
          <v-text-field
            v-model.trim="search"
            placeholder="Code"
            class="mr-3"
            outlined
            dense
            color="secondary"></v-text-field>
          <v-btn
            :loading="loading"
            @click="query = { ...query, search , page: 1 }">
            <v-icon left>
              mdi-magnify
            </v-icon>
            Search
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          sm="12"
          md="2"
          lg="3"
          xl="3"
          class="d-flex align-right">
          <v-btn
            color="secondary"
            :to="{ name: 'StockCountCreate' }"
            class="ml-auto">
            <v-icon left>
              mdi-plus
            </v-icon>
            สร้างใบสั่งนับ
          </v-btn>
        </v-col>
      </v-row>
      <p class="text-h6">
        รายการใบสั่งนับ
      </p>
      <v-tabs
        v-model="tab"
        background-color="dark"
        dark
        @change="(v) => query = { ...query, status: status[v].value, page: 1 }"
      >
        <v-tab
          v-for="(item, index) in status"
          :key="`${item.value}-${index}`"
        >
          {{ item.label }}
        </v-tab>
      </v-tabs>

      <v-data-table
        :headers="headers"
        :items="items"
        hide-default-footer
        class="elevation-1"
        :loading="loading"
        loading-text="Loading... Please wait">
        <template v-slot:item.warehouse="{ item }">
          {{ item.warehouse.name || '-' }}
        </template>
        <template v-slot:item.countDate="{ item }">
          {{ item.countDate | dateUserFormat }}
        </template>
        <template v-slot:item.status="{ item }">
          <p :class="`mb-0 ${getStatusLabel(item.status, 'color')}--text`">
            {{ getStatusLabel(item.status, 'label') }}
          </p>
        </template>
        <template v-slot:item.createdAt="{ item }">
          {{ item.createdAt | dateTimeUserFormat }}
        </template>
        <template v-slot:item.notes="{ item }">
          {{ item.notes.length ? item.notes[item.notes.length - 1].message : '-' }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            class="mr-3"
            icon
            :loading="loading"
            :to="{ name: 'StockCountEdit', params: { id: item.id } }">
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn
            class="mr-3"
            icon
            :loading="loading"
            :to="{ name: 'StockCountPreview', params: { id: item.id } }">
            <v-icon>
              mdi-file-document-outline
            </v-icon>
          </v-btn>
          <v-btn
            v-if="item.status === 'ready'"
            color="red"
            icon
            :loading="loading"
            @click="confirmDelete(item.id)">
            <v-icon>
              mdi-trash-can
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <div class="text-center mt-4">
        <v-pagination
          v-model="query.page"
          color="secondary"
          :length="totalPages"></v-pagination>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import StockCountProvider from '@/resources/StockCountProvider'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
  },
  data () {
    return {
      stockCountProvider: new StockCountProvider(),
      items: [],
      totalPages: 0,
      totalItems: 0,
      loading: false,
      search: '',
      tab: 0,
      status: [
        { value: '', label: 'ทั้งหมด', color: '' },
        { value: 'ready', label: 'พร้อมนับ', color: 'success' },
        { value: 'waiting', label: 'รอตรวจสอบ', color: 'indigo' },
        { value: 'completed', label: 'เสร็จสิ้น', color: 'grey' }
      ],
      defaultPaginate: {
        sortBy: 'createdAt',
        sortOrder: 'desc',
        warehouse: '',
        page: 1,
        limit: 10,
        search: '',
        status: ''
      },
      query: {
        sortBy: 'createdAt',
        sortOrder: 'desc',
        warehouse: '',
        page: 1,
        limit: 10,
        search: '',
        status: ''
      },
      headers: [
          {
            text: 'Code',
            align: 'start',
            value: 'code',
            sortable: false
          },
          {
            text: 'Status',
            align: 'center',
            value: 'status',
            sortable: false
          },
          {
            text: 'วันที่เข้านับ',
            align: 'center',
            value: 'countDate',
            sortable: false
          },
          {
            text: 'วันที่สร้าง',
            align: 'center',
            value: 'createdAt',
            sortable: false
          },
          {
            text: 'Warehouse / Location',
            align: 'center',
            value: 'warehouse.name',
            sortable: false
          },
          {
            text: 'Note',
            align: 'center',
            value: 'notes',
            sortable: false,
            width: 200
          },
          { text: 'Actions', align: 'left', value: 'actions', sortable: false }
        ]
    }
  },
 computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse'
    }),
    warehouses () {
      return [
        {
          id: '',
          name: 'All',
          code: 'all'
        },
        ...this.mapWarehouse.filter((warehouse) => warehouse.id !== 0)
      ]
    }
  },
    watch: {
    query: {
      handler () {
        this.fetchPaginate()
        this.updateQueryString()
      },
      deep: true
    }
  },
  mounted () {
    this.parseQueryString()
    this.fetchPaginate()
  },
  methods: {
    ...mapActions({
      setModal: 'Components/setModal'
    }),
    parseQueryString () {
      const queryParams = new URLSearchParams(this.$route.query)
      this.query = {
        ...this.query,
        sortBy: queryParams.get('sortBy') || 'createdAt',
        sortOrder: queryParams.get('sortOrder') || 'desc',
        warehouse: parseInt(queryParams.get('warehouse')) || '',
        page: parseInt(queryParams.get('page')) || 1,
        limit: parseInt(queryParams.get('limit')) || 10,
        search: queryParams.get('search') || '',
        status: queryParams.get('status') || ''
      }
      this.search = queryParams.get('search') || ''
    },
    updateQueryString () {
      const queryString = new URLSearchParams(this.query).toString()

      const currentQueryString = new URLSearchParams(this.$route.query).toString()

      if (queryString !== currentQueryString) {
        this.$router.push({ query: this.query }).catch((err) => {
          if (err.name !== 'NavigationDuplicated') {
            console.error(err)
          }
        })
      }
    },
    getStatusLabel (status, attribute = 'label') {
      return this.status.find((st) => st.value === status)[attribute] ?? '-'
    },
    async fetchPaginate (isClear = false) {
      if (isClear) {
        this.query = this.defaultPaginate
      }
      try {
        this.loading = true
        const { data } = await this.stockCountProvider.paginate(this.query)
        if (data) {
          this.items = data.results
          this.totalPages = data.pages
          this.totalItems = data.total
        }
      } catch (error) {
        console.error('fetchPaginate', error)
      }
      this.loading = false
    },
    confirmDelete (stockCountId) {
      this.setModal({
        value: true,
        title: 'ลบใบสั่งนับ',
        message: 'ต้องการลบใบสั่งนับหรือไม่?',
        confirmText: 'ต้องการ',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'ยกเลิก',
        onConfirm: () => this.deleteStockCount(stockCountId)
      })
    },
    async deleteStockCount (stockCountId) {
      this.loading = true
      try {
        await this.stockCountProvider.deleteById(stockCountId)
        if (this.query.page > 1 && this.items.length === 1) {
          this.query.page -= 1
        } else {
          this.fetchPaginate(true)
        }
      } catch (error) {
        console.error('deleteStockCount', error)
      }
      this.loading = false
    }
  }
}
</script>
